import { useState } from 'react';
import styles from './InterestChips.module.scss';
import strings from '../../i18n/i18nStrings';
import Button, { BUTTON_VARIANTS } from '../../ui/button/Button';

const InterestChips = ({ interests }: { interests: any[] }) => {
    const [showAllInterests, setShowAllInterests] = useState(false);
    const interestChips = [];

    for (let i = 0; i < ((showAllInterests || interests.length < 3) ? interests.length : 3); i++) {
        interestChips.push(
            <div
                key={i}
                className={styles.interestChip}
            >
                <span>{interests[i].name}</span>
            </div>
        );
    }

    return (
        <div>
            <div className={styles.interestChipContainer}>
                {interestChips}
                {
                    interests.length > 3 && (
                        <Button
                            roleVariant={BUTTON_VARIANTS.GHOST}
                            onPress={() => setShowAllInterests(!showAllInterests)}
                        >
                            <span className={styles.showMore}>
                                {
                                    showAllInterests ? 
                                        strings.common.showLess :
                                        strings.userProfileScreen.moreInterests(interests.length - 3)
                                }
                            </span>
                        </Button>
                    )
                }
            </div>
        </div>

    );
}

export default InterestChips;