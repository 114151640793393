import {
  Route,
  Navigate,
  Routes,
  BrowserRouter
} from 'react-router-dom';
import HomePage from '../homePage/HomePage';
import DashboardPage from '../dashboardPage/DashboardPage';
import { useAppContext } from '../../contexts/AppContext';
import EventsPage from '../eventsPage/EventsPage';
import { NAVIGATION_IDS } from '../../components/common/constants/navigation';

const Navigation = () => {
    const { isSignedIn } = useAppContext();

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path='/'
                    element={<HomePage />}
                />
                {
                    isSignedIn && (
                        <>
                            <Route
                                path={NAVIGATION_IDS.LOGIN}
                                element={<div>Log In</div>}
                            />
                            <Route
                                path={NAVIGATION_IDS.DASHBOARD}
                                element={<DashboardPage />}
                            />
                            <Route
                                path={NAVIGATION_IDS.EVENTS}
                                element={<EventsPage />}
                            />
                        </>
                    )
                }
                <Route
                    path='*'
                    element={<Navigate to="/" replace />}
                />
            </Routes>
        </BrowserRouter>
    )
}

export default Navigation;
