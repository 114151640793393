import { ReactComponent as PlusIcon } from '../../../assets/icons/PlusIcon.svg';
import { ReactComponent as MinusIcon } from '../../../assets/icons/MinusIcon.svg';
import styles from '../PhotoUpload.module.scss';
import { LAMP_POST_COLORS } from "../../../ui/constants";

const PhotoCell = ({
    photoUrl,
    selectUploadImage,
    deleteImage
}: {
    photoUrl?: string;
    selectUploadImage: (evt: any) => void;
    deleteImage: () => void;
}) => photoUrl ? (
    <div className={styles.photoCell}>
        <img
            src={photoUrl}
            className={styles.photoCellImage}
            alt="User Submission"
        />
        <div
            className={styles.deleteIcon}
            onClick={deleteImage}
        >
            <MinusIcon
                width={26}
                fill={LAMP_POST_COLORS.white}
            />
        </div>
    </div>
) : (
    <>
        <div
            className={`${styles.photoCell} ${styles.emptyCell}`}
            onClick={selectUploadImage}
        >
            <PlusIcon
                width={30}
                fill={LAMP_POST_COLORS.amber}
            />
        </div>
    </>
);

export default PhotoCell;