import styles from './Button.module.scss';

export const BUTTON_VARIANTS = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    GHOST: 'ghost',
    DESTRUCTIVE: 'destructive',
    CTA: 'cta'
}

const Button = ({
    onPress,
    roleVariant = BUTTON_VARIANTS.PRIMARY,
    disabled = false,
    children
}: {
    text?: string;
    onPress: () => void;
    roleVariant?: string;
    disabled?: boolean;
    children: React.ReactElement;
}) => (
    <button 
        onClick={onPress}
        className={`${styles.button} ${styles[`${roleVariant}Button`]} ${disabled ? styles.disabled : ''}`}
        disabled={disabled}
    >
        {children}
    </button>
);

export default Button;