export const sortEvents = (events: any[], isAscending = true) => {
    if (isAscending) {
        return events.sort((eventA: { date: number; }, eventB: { date: number; }) => eventA.date - eventB.date);
    }
    return events.sort((eventA: { date: number; }, eventB: { date: number; }) => eventB.date - eventA.date);
}

const sortWeighted = (eventA: { date: number; calculatedDistance: string; }, eventB: { date: number; calculatedDistance: string; }) => {
    const dateWeight = 0.9;
    const proximityWeight = 0.1;

    const eventAWeight = (eventA.date * dateWeight) + (parseFloat(eventA.calculatedDistance) * proximityWeight);
    const eventBWeight = (eventB.date * dateWeight) + (parseFloat(eventB.calculatedDistance) * proximityWeight);

    return eventAWeight - eventBWeight;
}

export const sortEventsWeighted = (events: any[], isAscending = true) => {
    if (isAscending) {
        return events.sort((eventA: any, eventB: any) => sortWeighted(eventA, eventB));
    }
    return events.sort((eventA: any, eventB: any) => sortWeighted(eventB, eventA));
}