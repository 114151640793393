import { ReactElement, createContext, useContext, useState } from 'react';
import { lampPostFetch } from '../utils/fetchHelpers';
import {
    CREATE_EVENT,
    DELETE_EVENT,
    GET_SELF,
    GET_USER,
    UPDATE_EVENT,
    UPDATE_USER
} from '../components/common/constants/endpoints';
import { GET_SERVER } from '../components/common/constants/servers';

type AppContextValues = {
    userInfo: any;
    isSignedIn: boolean;
    signIn: (email: string, password: string, callback?: () => void) => void;
    logOut: () => void;
    updateUserInfo: (changedUserInfo: any, callback?: () => void) => void;
    createOrEditEvent: (newEvent: object, isAttending: boolean, onSuccess?: () => void) => void;
    deleteEvent: (eventId: string) => void;
    server: string;
}

export const AppContext = createContext({} as AppContextValues);

export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({ children }: { children: ReactElement }) => {
    const [userInfo, setUserInfo] = useState<any>(
        window.sessionStorage.getItem('userInfo') ?
            JSON.parse(window.sessionStorage.getItem('userInfo') as string) :
            null
    );
    const [isSignedIn, setIsSignedIn] = useState(!!userInfo);

    const signIn = (
        enteredEmail: string, 
        enteredPassword: string,
        callback?: () => void
    ) => {
        if (enteredEmail && enteredPassword) {
            lampPostFetch(
                GET_USER(enteredEmail, enteredPassword),
                { method: "GET" },
                (result: any) => {
                    if (result) {
                        setUserInfo({ ...result });
                        setIsSignedIn(true);
                        window.sessionStorage.setItem('userInfo', JSON.stringify(result));

                        if (callback) {
                            callback();
                        }
                    }
                }
            );
        }
    }
    
    const logOut = () => {
        setIsSignedIn(false);
        setUserInfo(null)
        window.sessionStorage.setItem('userInfo', '');
    }

    const getUpdatedUser = () => {
        lampPostFetch(
            GET_SELF(userInfo?._id),
            { method: "GET" },
            (result) => {
                if (result) {
                    setUserInfo({ ...result });
                }
            }
        );
    }

    const updateUserInfo = (changedUserInfo: any, callback?: () => void) => {
        lampPostFetch(
            UPDATE_USER(userInfo?._id),
            {
                method: "PATCH",
                body: JSON.stringify({
                    ...userInfo,
                    ...changedUserInfo,
                    completedAccount: true
                })
            },
            (result: any) => {
                if (result) {
                    setUserInfo({ ...result });

                    if (callback) {
                        callback();
                    }
                }
            }
        );
    }

    const createOrEditEvent = (eventInfo: any, isAttending: boolean = true, onSuccess?: () => void) => {
        const locationData = eventInfo.location.geometry ? {
            id: eventInfo.location.place_id,
            name: eventInfo.location.name,
            address: eventInfo.location.formatted_address,
            lat: eventInfo.location.geometry.location.lat(),
            lng: eventInfo.location.geometry.location.lng(),
        } : eventInfo.location;

        const fetchOptions: RequestInit = {
            headers: { "Content-type": "application/json", },
            method: eventInfo._id ? "PATCH" : "POST",
            body: JSON.stringify({
                ...eventInfo,
                attendees: isAttending ? [userInfo?._id] : [],
                creator: userInfo?._id || -1,
                location: locationData,
                isAttending,
            })
        };

        lampPostFetch(
            eventInfo._id ? UPDATE_EVENT(eventInfo._id) : CREATE_EVENT,
            fetchOptions,
            (result: { _id: any; }) => {
                if (result) {
                    getUpdatedUser();
                    
                    if (onSuccess) {
                        onSuccess();
                    }
                }
            }
        );
    }

    const deleteEvent = (eventId: string) => {
        lampPostFetch(DELETE_EVENT(eventId), { method: "DELETE" });
    }

    return (
        <AppContext.Provider
            value={{
                userInfo,
                isSignedIn,
                signIn,
                logOut,
                updateUserInfo,
                createOrEditEvent,
                deleteEvent,
                server: GET_SERVER()
            }}
        >
            {children}
        </AppContext.Provider>
    )
}