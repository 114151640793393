import Label from "../label/Label";
import strings from "../../i18n/i18nStrings";
import styles from './DatePicker.module.scss';

const DatePicker = ({
    initialValue,
    onChange,
}: {
    initialValue?: Date;
    onChange: (date: Date) => void;
}) => (
    <div>
        <Label label={strings.createOrEditEventScreen.eventDateTime} />
        <div>
            <input
                aria-label="Date and time"
                type="datetime-local"
                onChange={e => onChange(new Date(e.target.value))}
                // value={value}
                className={styles.datePicker}
            />
        </div>
    </div>
);

export default DatePicker;