const homeStrings = {
    homeScreen: {
        createEvent: 'Create Event',
        eventList: {
            title: 'Events',
            description: 'Monitor, track, and respond to all system events in real-time.'
        },
        analytics: {
            title: 'Analytics',
            description: 'Dive into data with powerful analytics and actionable insights.'
        },
        settings: {
            title: 'Settings',
            description: 'Tailor and secure your system with flexible configuration options.'
        },
    }
}

export default homeStrings;