const createEventStrings = {
    createOrEditEventScreen: {
        createEvent: 'Create Event',
        editEvent: 'Edit Event',
        eventName: 'Event Name',
        eventHost: 'Event Host',
        eventLocation: 'Location',
        eventDateTime: 'Date / Time',
        eventCost: 'Cost',
        eventDescription: 'Description (min. 5, max. 250)',
        eventTags: 'Tags',
        eventRepeating: 'Repeats',
        attendingEvent: 'Are you attending?',
        eventPlaceholder: 'Type a location'
    }
}

export default createEventStrings;