const messagesStrings = {
    messagesScreen: {
        likeToTalk: (name: string) => `${name} would like to talk with you`,
        waitingFor: (name: string) => `Waiting for ${name} to respond`,
        ignore: 'Ignore',
        respond: 'Respond',
        noChats: "You don't have any active chats"
    }
}

export default messagesStrings;