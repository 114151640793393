import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as LogoIcon } from '../../assets/icons/LogoIcon.svg';
import { LAMP_POST_COLORS } from '../../ui/constants';
import { NAVIGATION_IDS, NAV_LIST_DATA } from '../common/constants/navigation';
import styles from './Header.module.scss';
import { useEffect, useRef, useState } from 'react';
import Button, { BUTTON_VARIANTS } from '../../ui/button/Button';
import { useAppContext } from '../../contexts/AppContext';
import strings from '../../i18n/i18nStrings';

const Header = ({
    onLoginPress
} : {
    onLoginPress?: () => void;
}) => {
    const { isSignedIn, logOut } = useAppContext();
    const navigate = useNavigate();
    const location = useLocation();

    const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);

    const menuRef = useRef<any>(null);
    const buttonRef = useRef<any>(null);

    const onNavigation = (url: string) => {
        setIsNavMenuOpen(false);
        navigate(`/${url}`);
    }

    const toggleOpenMenu = () => {
        setIsNavMenuOpen(prevState => !prevState);
    }

    const handleOutsideClick = (event: { target: any; }) => {
        if (
            menuRef.current &&
            !menuRef.current.contains(event.target) &&
            !buttonRef.current.contains(event.target)
        ) {
            setIsNavMenuOpen(false);
        }
      };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    return (
        <div className={styles.header}>
            <div
                onClick={() => navigate(`/${NAVIGATION_IDS.DASHBOARD}`)}
                className={styles.logo}
            >
                <LogoIcon width={64} fill={LAMP_POST_COLORS.amber} />
            </div>
            <div
                onClick={() => navigate(`/${NAVIGATION_IDS.DASHBOARD}`)}
                className={styles.title}
            >
                {
                    location.pathname !== '/' && strings.common.lampPost
                }
            </div>
            
            {
                location.pathname === '/' && onLoginPress ? (
                    <div>
                        <Button onPress={onLoginPress}>
                            <span>{strings.landingScreen[isSignedIn ? 'dashboard' : 'login']}</span>
                        </Button>
                    </div>
                ) : (
                    <div className={styles.menuContainer}>
                        <div ref={buttonRef}>
                            <Button
                                onPress={toggleOpenMenu}
                                roleVariant={BUTTON_VARIANTS.GHOST}
                            >
                                <div className={styles.menuButton}>
                                    <span />
                                    <span />
                                    <span />
                                </div>
                            </Button>
                        </div>
                        {
                            isNavMenuOpen && (
                                <div
                                    className={styles.menuList}
                                    ref={menuRef}
                                >
                                    {
                                        Object.values(NAV_LIST_DATA).map(navListItem => (
                                            <div
                                                key={navListItem.url}
                                                onClick={() => onNavigation(navListItem.url)}
                                                className={styles.menuItem}
                                            >
                                                <span>{navListItem.title}</span>
                                            </div>
                                        ))
                                    }
                                    <div className={styles.logoutButtonContainer}>
                                        <Button
                                            onPress={logOut}
                                            roleVariant={BUTTON_VARIANTS.DESTRUCTIVE}
                                        >
                                            <span>{strings.common.logOut}</span>
                                        </Button>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )
            }
        </div>
    )
}

export default Header;