import { Key, useEffect, useState } from 'react';
import Button, { BUTTON_VARIANTS } from '../../ui/button/Button';
import strings from '../../i18n/i18nStrings';
import styles from './EventDetailsPreview.module.scss'
import { lampPostFetch } from '../../utils/fetchHelpers';
import { GET_EVENT } from '../common/constants/endpoints';
import InterestChips from '../interestChips/InterestChips';
import { FILTER_TAGS } from '../filters/filters.constants';
import PhotoRoll from '../photoRoll/PhotoRoll';
import { getDistanceOneToOne } from '../../utils/getDistance';

const EventDetailsPreview = ({
  eventId
}: {
  eventId: string;
}) => {
  const [eventData, setEventData] = useState<any>(null);
  const [currentLocation, setCurrentLocation] = useState<any>({});
  const [distance, setDistance] = useState(0);

  useEffect(() => {
    lampPostFetch(GET_EVENT(eventId), {}, setEventData);
    navigator.geolocation.getCurrentPosition(setCurrentLocation);
  }, [eventId]);

  useEffect(() => {
    if (eventData && currentLocation?.coords?.latitude) {
      (async () => {
        const retrievedDistance = await getDistanceOneToOne(
          currentLocation.coords.latitude,
          currentLocation.coords.longitude,
          eventData.location.lat,
          eventData.location.lng,
        );

        setDistance(retrievedDistance.text);
      })();
    }
  }, [currentLocation, eventData])

  const formatAddress = () => {
    const address = eventData.location.address || eventData.location.formatted_address;
    const splitAddress = address.split(',');

    return [
      splitAddress[0],
      splitAddress[1],
      splitAddress[2],
      `(${distance})`
    ];
  }

  return eventData ? (
    <>
      <div className={styles.eventPreview}>
        {
          eventData?.images?.length ? <PhotoRoll photoUrls={eventData.images} /> : null
        }
        <div className={styles.infoContainer}>
          <div className={styles.sizedInfo}>
            <div className={styles.infoItem}>
              <p className={styles.eventName}>{eventData.name}</p>
            </div>
            <div className={styles.infoItem}>
              <p className={styles.eventLocation}>{eventData.location.name}</p>
            </div>
            <div className={styles.infoItem}>
              {formatAddress().map((addressPiece, idx) => (
                <p
                  key={idx}
                  className={styles.locationText}
                >
                  {addressPiece}
                </p>
              ))}
            </div>
            <div className={styles.infoItem}>
              <p className={styles.infoItemText}>
                {
                  strings.common.datePicker(
                    new Date(eventData.date).toLocaleDateString(),
                    new Date(eventData.date).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })
                  )
                }
              </p>
            </div>
            <div className={`${styles.attendeeList} ${styles.infoItem}`}>
              <div className={styles.attendeeImagesContainer}>
                {
                  eventData.attendees.slice(0, 3).map((attendee: { attendeeImage: string | undefined; }, idx: Key | null | undefined) => (
                      <div key={idx}>
                          <img
                            src={attendee.attendeeImage }
                            className={styles.attendeeImage}
                            alt="Attendee"
                          />
                      </div>
                  ))
                }
              </div>
              {
                eventData.attendees.length > 3 ? (
                  <p className={styles.infoItemText}>
                    {strings.eventDetailsScreen.additionalAttendees(eventData.attendees.length - 3)}
                  </p>
                ) : null
              }
            </div>
            <div className={eventData?.tags?.length ? styles.infoItem : ''}>
              <p className={styles.infoItemText}>
                {strings.eventDetailsScreen.cost(
                  eventData.cost > 0 ? eventData.cost.toFixed(2) : strings.eventDetailsScreen.free
                )}
              </p>
            </div>
            {
              eventData?.tags?.length ? (
                <InterestChips
                  interests={eventData.tags.map((tag: string) => Object.values(FILTER_TAGS).find(filterTag => filterTag.id === parseInt(tag)))}
                />
              ) : null
            }
          </div>
        </div>
        <p>{eventData.description}</p>
      </div>
      {
        new Date().getTime() < eventData.date && (
          <div className={styles.rsvpContainer}>
            <Button
              roleVariant={BUTTON_VARIANTS.CTA}
              onPress={() => {}}
            >
              <span>{strings.eventDetailsScreen.join}</span>
            </Button>
          </div>
        )
      }
    </>
  ) : <div>{strings.common.loading}</div>;
};

export default EventDetailsPreview;
