import { ReactElement } from "react";
import { createPortal } from "react-dom";
import styles from "./Modal.module.scss";
import Button, { BUTTON_VARIANTS } from "../button/Button";

const Modal = ({
    isOpen,
    onClose,
    isThin,
    children
}: {
    isOpen: boolean;
    onClose: () => void;
    isThin?: boolean;
    children: ReactElement
}) => {
    const modalRoot = document.getElementById('root');

    if (!isOpen || !modalRoot) {
        return <div />;
    }

    return createPortal(
        <div className={styles.modal}>
            <div className={styles.modalBackground} onClick={onClose} />
            <div className={`${styles.modalContents}  ${isThin ? styles.thin : ''}`}>
                <div className={styles.close} onClick={onClose}>
                    <Button
                        roleVariant={BUTTON_VARIANTS.GHOST}
                        onPress={onClose}
                    >
                        <span>X</span>
                    </Button>
                </div>
                {children}
            </div>
        </div>,
        modalRoot
    );
}

export default Modal;