// User Endpoints
export const CREATE_USER = "/user/createUser";
export const GET_USER = (email: string, password: string) => `/user/findUser/${email}/${password}`;
export const GET_USER_BY_ID = (userId: string) => `/user/getUser/${userId}`;
export const GET_SELF = (userId: string) => `/user/getSelf/${userId}`;
export const UPDATE_USER = (userId: string) => `/user/updateUser/${userId}`;

// Event Endpoints
export const CREATE_EVENT = "/event/createEvent";
export const GET_EVENT = (eventId: string) => `/event/getEvent/${eventId}`;
export const GET_EVENTS = "/event/getEvents";
export const UPDATE_EVENT = (eventId: string) => `/event/updateEvent/${eventId}`;
export const DELETE_EVENT = (eventId: string) => `/event/delete/${eventId}`;
export const JOIN_EVENT = (eventId: string, userId: string) => `/event/attendEvent/${eventId}/${userId}`;

// Message Endpoints
export const CREATE_MESSENGER = "/messenger/createMessenger";
export const SEND_MESSAGE = (messengerId: string) => `/messenger/sendMessage/${messengerId}`;
export const INITIATE_MESSENGER = (userId: string) => `/messenger/initiateMessenger/${userId}`;
export const GET_MESSENGER_BY_ID = (userId: string, messengerId: string) => `/messenger/getMessenger/${userId}/${messengerId}`;
export const GET_ALL_MESSENGERS = (userId: string) => `/messenger/getAllMessengers/${userId}`;
export const DELETE_MESSENGER = (chatId: string) => `/messenger/deleteMessenger/${chatId}`;