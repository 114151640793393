import { useEffect, useState } from "react";
import Input from "../../../ui/input/Input";
import Dropdown from "../../../ui/dropdown/Dropdown";
import { FILTER_TAGS } from "../../../components/filters/filters.constants";
import strings from "../../i18nStrings";
import PhotoUpload from "../../../components/photoUpload/PhotoUpload";
import RadioButtonGroup from "../../../ui/radioButtonGroup/RadioButtonGroup";
import LocationAutocomplete from "../../../ui/locationAutocomplete/LocationAutocomplete";
import LampPostDatePicker from "../../../ui/datePicker/DatePicker";
import Button, { BUTTON_VARIANTS } from "../../../ui/button/Button";
import { useAppContext } from "../../../contexts/AppContext";
import styles from './CreateOrEditEvent.module.scss';
import { REPEAT_VALUES } from "../../../components/common/constants/createEvent";

const CreateOrEditEvent = ({
    onCancel,
    onSuccess,
    eventInfo
} : {
    onCancel: () => void;
    onSuccess?: () => void;
    eventInfo?: any;
}) => {
    const { createOrEditEvent } = useAppContext();
    const currentDate = new Date();

    const [eventName, setEventName] = useState(eventInfo?.name || '');
    // const [eventHost, setEventHost] = useState(eventInfo?.name || '');
    const [eventLocation, setEventLocation] = useState(eventInfo?.location || null);
    const [eventDateTime, setEventDateTime] = useState(
        new Date(eventInfo?.date ? eventInfo?.date : currentDate.setDate(currentDate.getDate() + 1))
    );
    const [eventCost, setEventCost] = useState(eventInfo?.cost || 0);
    const [eventDescription, setEventDescription] = useState(eventInfo?.description || '');
    const [eventTags, setEventTags] = useState<any[] | undefined>(eventInfo?.tags || []);
    const [eventRepeatOption, setEventRepeatOption] = useState(eventInfo?.repeatType || 0);
    const [eventPhotos, setEventPhotos] = useState<string[]>(eventInfo?.images || []);
    const [isAttending, setIsAttending] = useState(true);
    const [isSubmitEnabled, isSetSubmitEnabled] = useState(true);

    useEffect(() => {
        isSetSubmitEnabled(
            !!eventName &&
            // !!eventHost &&
            !!eventLocation &&
            !!eventDateTime &&
            eventCost !== null &&
            !!eventDescription
        );
    }, [
        eventName,
        // eventHost,
        eventLocation,
        eventDateTime,
        eventCost,
        eventDescription
    ]);

    const submitEvent = () => {
        createOrEditEvent(
            {
                _id: eventInfo?._id || null,
                name: eventName,
                date: eventDateTime.getTime(),
                location: eventLocation,
                description: eventDescription,
                images: eventPhotos,
                cost: eventCost,
                tags: eventTags,
                repeatType: eventRepeatOption
            },
            isAttending,
            onSuccess
        );

        setEventName('');
        setEventLocation(null);
        setEventDateTime(new Date(currentDate.setDate(currentDate.getDate() + 1)));
        setEventCost(0);
        setEventDescription('');
        setEventTags([]);
        setEventPhotos([]);
        setEventRepeatOption(0);
        setIsAttending(false);
        isSetSubmitEnabled(true);

        onCancel();
    }

    return (
        <>
            <h2>
                {strings.createOrEditEventScreen[eventInfo?._id ? 'editEvent' : 'createEvent']}
            </h2>
            <div className={styles.editContentsContainer}>
                <Input
                    label={strings.createOrEditEventScreen.eventName}
                    text={eventName}
                    onChange={setEventName}
                />
                {/* <Input
                    label="Event Host"
                    text={eventName}
                    onChange={setEventName}
                /> */}
                <LocationAutocomplete
                    initialLocation={eventLocation}
                    onChange={setEventLocation}
                />
                <LampPostDatePicker
                    initialValue={eventDateTime}
                    onChange={setEventDateTime}
                />
                <Input
                    label={strings.createOrEditEventScreen.eventCost}
                    text={eventCost.toString()}
                    onChange={(enteredCost) => setEventCost(parseFloat(enteredCost) || 0)}
                />
                <Input
                    label={strings.createOrEditEventScreen.eventDescription}
                    text={eventDescription}
                    onChange={setEventDescription}
                    numberOfLines={5}
                />
                <RadioButtonGroup
                    options={[{
                        id: 0,
                        name: strings.common.yes
                    }, {
                        id: 1,
                        name: strings.common.no
                    }]}
                    onSelect={(selection) => setIsAttending(selection.id === 0)}
                    label={strings.createOrEditEventScreen.attendingEvent}
                />
                <Dropdown
                    setOptions={eventTags?.map((eventTag: any) => Object.values(FILTER_TAGS).find(tag => tag.id === eventTag))}
                    options={Object.values(FILTER_TAGS)}
                    onSelect={(tags) => setEventTags(tags?.map(tag => tag.id))}
                    label={strings.createOrEditEventScreen.eventTags}
                    multiSelect
                />
                <Dropdown
                    setOptions={[Object.values(REPEAT_VALUES).find(repeat => repeat.id === eventRepeatOption)]}
                    options={Object.values(REPEAT_VALUES)}
                    onSelect={(repeatOption: any) => setEventRepeatOption(repeatOption[0]?.id)}
                    label={strings.createOrEditEventScreen.eventRepeating}
                />
                <PhotoUpload
                    uploadedPhotos={eventPhotos}
                    onUpload={setEventPhotos}
                />
                <div className={styles.respondToButtonContainer}>
                    <div className={styles.buttonContainer}>
                        <Button
                            onPress={onCancel}
                            roleVariant={BUTTON_VARIANTS.SECONDARY}
                        >
                            <span>{strings.common.cancel}</span>
                        </Button>
                    </div>
                    <div className={styles.buttonContainer}>
                        <Button
                            onPress={submitEvent}
                            disabled={!isSubmitEnabled}
                        >
                            <span>{strings.common.submit}</span>
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateOrEditEvent;