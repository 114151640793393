import { DISTANCE_KEY } from "../services/api/placesKey";

export const getDistanceOneToOne = async (lat1: string, lng1: string, lat2: string, lng2: string) => {
    const location1Str = lat1 + "," + lng1;
    const location2Str = lat2 + "," + lng2;
  
    const apiURL = "https://maps.googleapis.com/maps/api/distancematrix/json?";
    const params = `origins=${location1Str}&destinations=${location2Str}&key=${DISTANCE_KEY}&units=imperial`;
    const finalApiURL = `${apiURL}${encodeURI(params)}`;
  
    const fetchResult =  await fetch(finalApiURL); // call API
    const fetchJson = await fetchResult.json();
    return await fetchJson.rows[0].elements[0].distance;
}