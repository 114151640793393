import { useRef, useState } from "react";
import strings from "../../i18n/i18nStrings";
import PhotoCell from "./photoCell/PhotoCell";
import styles from './PhotoUpload.module.scss';
import Label from "../../ui/label/Label";

const PhotoUpload = ({
    uploadedPhotos,
    onUpload
}: {
    uploadedPhotos?: string[]
    onUpload: (photoUrls: string[]) => void;
}) => {
    const inputRef = useRef<any>();

    const [photoUrls, setPhotoUrls] = useState(uploadedPhotos || []);
    const photoCells = [];

    const uploadNewPhoto = (base64Data: string | ArrayBuffer | null) => {
        const urlsWithNewPhoto = [...photoUrls];
        urlsWithNewPhoto.push(base64Data as string);

        // Upload photo
        setPhotoUrls(urlsWithNewPhoto);
        onUpload(urlsWithNewPhoto);
    }

    const selectUploadImage = (evt: { target: { files: any; }; }) => {
        const reader = new FileReader();

        reader.onload = () => {
            uploadNewPhoto(reader.result);
        };

        reader.onerror = (error) => {
            console.log('Error: ', error);
        };
        
        if (evt.target.files[0]) {
            reader.readAsDataURL(evt.target.files[0]);
        }
    };

    const deleteImage = (photoIndex: number) => {
        const urlsWithNewPhoto = [...photoUrls];
        urlsWithNewPhoto.splice(photoIndex, 1);

        // Upload photo
        setPhotoUrls(urlsWithNewPhoto);
        onUpload(urlsWithNewPhoto);
    }

    for (let i = 0; i < 5; i++) {
        photoCells.push(
            <PhotoCell
                key={i}
                photoUrl={photoUrls[i] || ''}
                selectUploadImage={() => inputRef.current.click()}
                deleteImage={() => deleteImage(i)}
            />
        );
    }

    return (
        <div>
            <Label label={strings.common.photos} />
            <input
                className={styles.fileInput}
                type="file"
                accept="image/*,.pdf"
                onChange={selectUploadImage}
                ref={inputRef}
            />
            <div className={styles.photoCellsContainer}>
                {photoCells}
            </div>
        </div>
    )
}

export default PhotoUpload;