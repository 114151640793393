import { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginModal from "../../components/loginModal/LoginModal";
import styles from "./HomePage.module.scss";
import strings from "../../i18n/i18nStrings";
import { useAppContext } from "../../contexts/AppContext";
import { NAVIGATION_IDS } from "../../components/common/constants/navigation";
import Header from "../../components/header/Header";

const HomePage = () => {
    const navigate = useNavigate();
    const { isSignedIn } = useAppContext();
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

    const onLoginPress = () => {
        if (isSignedIn) {
            navigate(NAVIGATION_IDS.DASHBOARD);
        } else {
            setIsLoginModalOpen(true);
        }
    }

    return (
        <>
            <Header onLoginPress={onLoginPress} />
            <div className={styles.heroContainer}>
                <div className={styles.heroText}>
                    <span>{strings.common.lampPost}</span>
                </div>
                <div className={styles.heroDescription}>
                    <span>{strings.landingScreen.catchphrase}</span>
                </div><div className={styles.downloadDescription}>
                    <span>{strings.landingScreen.download}</span>
                </div>
                <div className={styles.qrContainer}>
                    <div className={styles.qrSection}>
                        <div>{strings.landingScreen.ios}</div>
                        <div className={styles.fauxQR}>
                            <div />
                        </div>
                    </div>
                    <div className={styles.qrSection}>
                        <div>{strings.landingScreen.android}</div>
                        <div className={styles.fauxQR}>
                            <div />
                        </div>
                    </div>
                </div>
            </div>
            <LoginModal
                isOpen={isLoginModalOpen}
                onClose={() => setIsLoginModalOpen(false)}
            />
        </>
    )
}

export default HomePage;