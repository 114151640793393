const strings = {
    filters: {
        cost: {
            any : 'Any cost',
            free: 'Free',
            ten: '10 and under',
            twenty: '20 and under',
            fifty: '50 and under',
            hundred: '100 and under',
            twoHundred: '200 and under',
            fiveHundred: '500 and under',
            thousand : '1000 and under',
        },
        distance: {
            any: 'Any distance',
            oneMile: 'Within 1 mile',
            fiveMiles: 'Within 5 miles',
            tenMiles: 'Within 10 miles',
            twentyFiveMiles: 'Within 25 miles',
            fiftyMiles: 'Within 50 miles',
            hundredMiles: 'Within 100 miles',
        },
        tags: {
            artsAndCulture: 'Arts & Culture',
            charityAndFundraisers: 'Charity & Fundraisers',
            comedyAndEntertainment: 'Comedy & Entertainment',
            communityAndLifestyle: 'Community & Lifestyle',
            conferencesAndWorkshops: 'Conferences & Workshops',
            musicAndConcerts: 'Concerts & Music Festivals',
            educationalAndAcademic: 'Educational & Academic',
            exposAndTradeShows: 'Expos & Trade Shows',
            familyAndKids: 'Family & Kids',
            fashionAndBeauty: 'Fashion & Beauty',
            filmAndCinema: 'Film & Cinema',
            foodAndDrink: 'Food & Drink',
            healthAndWellness: 'Health & Wellness',
            holidayAndSeasonal: 'Holiday & Seasonal',
            outdoorAndNature: 'Outdoor & Nature',
            socialAndNetworking: 'Social & Networking',
            eventsAndCelebrations: 'Special Events & Celebrations',
            sportsAndFitness: 'Sports & Fitness',
            technologyAndInnovation: 'Technology & Innovation',
            travelAndAdventure: 'Travel & Adventure',
        }
    }
}

export default strings;
