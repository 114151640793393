import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { AppContextProvider } from './contexts/AppContext';
import Navigation from './pages/navigation/Navigation';
// import { FilterContextProvider } from './contexts/FilterContext';
// import { MessageCenterContextProvider } from './contexts/MessageCenterContext';

ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
).render(
  <AppContextProvider>
    {/* <FilterContextProvider>
      <MessageCenterContextProvider> */}
        <React.StrictMode>
          {/* <RouterProvider router={router} /> */}
          <Navigation />
        </React.StrictMode>
      {/* </MessageCenterContextProvider>
    </FilterContextProvider> */}
  </AppContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
