import { SetStateAction, useEffect, useState } from "react";
import { useAppContext } from "../../contexts/AppContext";
import PageLayout from "../common/PageLayout";
import { sortEvents } from "../../utils/sortEvents";
import Button, { BUTTON_VARIANTS } from "../../ui/button/Button";
import strings from "../../i18n/i18nStrings";
import CreateOrEditEvent from "../../i18n/components/createOrEditEvent/CreateOrEditEvent";
import EventsList from "../../components/eventList/EventList";
import Modal from "../../ui/modal/Modal";
import styles from "./EventsPage.module.scss";
import EventDetailsPreview from "../../components/eventDetailsPreview/EventDetailsPreview";

const EventsPage = () => {
    const {
        userInfo,
        server
    } = useAppContext();

    const [editingEvent, setEditingEvent] = useState(null);
    const [previewEvent, setPreviewEvent] = useState<any>(null);
    const [openEventModal, setOpenEventModal] = useState(false);
    const [upcomingEvents, setUpcomingEvents] = useState<any[]>([]);
    const [pastEvents, setPastEvents] = useState<any[]>([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loadingEvents, setLoadingEvents] = useState(false);
  
    useEffect(() => {
      (fetchEvents)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const fetchEvents = async () => {
        setLoadingEvents(true);
        
        Promise.all(userInfo.events.created.map((id: any) => 
            fetch(
                `${server}/event/getEvent/${id}`,
                {
                    headers: {
                        'Accept': 'application/json',
                        "Content-type": "application/json",
                        "ngrok-skip-browser-warning": "69420",
                    },
                }
            ).then(resp => resp.json())
        )).then(results => {
            const newUpcomingEvents: SetStateAction<any[]> = [];
            const newPastEvents: SetStateAction<any[]> = [];
            const currentDate = new Date().getTime();

            sortEvents(results.filter(result => result._id), false).forEach((event) => {
                if (event.date > currentDate) {
                    newUpcomingEvents.push(event);
                } else {
                    newPastEvents.push(event);
                }
            });
            
            setUpcomingEvents(newUpcomingEvents);
            setPastEvents(newPastEvents);
            setLoadingEvents(false);
          }
        );
    }


    const onCreateEvent = () => {
        setEditingEvent(null);
        setPreviewEvent(null);
        setOpenEventModal(true);
    }

    const onEditEvent = (editedEvent: any) => {
        setPreviewEvent(null);
        setEditingEvent(editedEvent);
        setOpenEventModal(true);
    }

    const onPreviewEvent = (editedEvent: any) => {
        setPreviewEvent(editedEvent);
        setEditingEvent(null);
        setOpenEventModal(false);
    }

    const onCloseEventEditor = () => {
        setEditingEvent(null);
        setPreviewEvent(null);
        setOpenEventModal(false);
    }

    return (
        <PageLayout>
            <>
                <div className={styles.createButtonContainer}>
                    <p className={styles.pageTitle}>{strings.eventDetailsScreen.eventList}</p>
                    <Button
                        onPress={onCreateEvent}
                        roleVariant={BUTTON_VARIANTS.CTA}
                    >
                        <span>{strings.createOrEditEventScreen.createEvent}</span>
                    </Button>
                </div>
                <div className={styles.listContainer}>
                    <p className={styles.listTitle}>{strings.eventDetailsScreen.upcomingEvents}</p>
                    <EventsList
                        events={upcomingEvents}
                        onEdit={onEditEvent}
                        onPreview={onPreviewEvent}
                        onCreateEvent={onCreateEvent}
                    />
                </div>
                <div className={styles.listContainer}>
                    <p className={styles.listTitle}>{strings.eventDetailsScreen.pastEvents}</p>
                    <EventsList
                        events={pastEvents}
                        onPreview={onPreviewEvent}
                    />
                </div>
                <Modal
                    isOpen={openEventModal}
                    onClose={onCloseEventEditor}
                >
                    <CreateOrEditEvent
                        eventInfo={editingEvent}
                        onCancel={onCloseEventEditor}
                        onSuccess={fetchEvents}
                    />
                </Modal>    
                <Modal
                    isOpen={!!previewEvent}
                    onClose={onCloseEventEditor}
                    isThin
                >
                    <EventDetailsPreview
                        eventId={previewEvent ? previewEvent._id : 0}
                    />
                </Modal>
            </>
        </PageLayout>
    )
}

export default EventsPage;