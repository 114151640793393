import strings from "../../i18n/i18nStrings";

export const FILTER_COSTS = {
    any: {
        name: strings.filters.cost.any,
        id: -1,
    },
    free: {
        name: strings.filters.cost.free,
        id: 0,
    },
    ten: {
        name: strings.filters.cost.ten,
        id: 10,
    },
    twenty: {
        name: strings.filters.cost.twenty,
        id: 20,
    },
    fifty: {
        name: strings.filters.cost.fifty,
        id: 50,
    },
    hundred: {
        name: strings.filters.cost.hundred,
        id: 100,
    },
    twoHundred: {
        name: strings.filters.cost.twoHundred,
        id: 200,
    },
    fiveHundred: {
        name: strings.filters.cost.fiveHundred,
        id: 500,
    },
    thousand: {
        name: strings.filters.cost.thousand,
        id: 1000,
    },
}

export const FILTER_DISTANCE = {
    any: {
        name: strings.filters.distance.any,
        id: -1,
    },
    oneMile: {
        name: strings.filters.distance.oneMile,
        id: 1,
    },
    fiveMiles: {
        name: strings.filters.distance.fiveMiles,
        id: 5,
    },
    tenMiles: {
        name: strings.filters.distance.tenMiles,
        id: 10,
    },
    twentyFiveMiles: {
        name: strings.filters.distance.twentyFiveMiles,
        id: 25,
    },
    fiftyMiles: {
        name: strings.filters.distance.fiftyMiles,
        id: 50,
    },
    hundredMiles: {
        name: strings.filters.distance.hundredMiles,
        id: 100,
    },
}

export const FILTER_TAGS = {
    artsAndCulture: {
        name: strings.filters.tags.artsAndCulture,
        id: 0
    },
    charityAndFundraisers: {
        name: strings.filters.tags.charityAndFundraisers,
        id: 1
    },
    comedyAndEntertainment: {
        name: strings.filters.tags.comedyAndEntertainment,
        id: 2
    },
    communityAndLifestyle: {
        name: strings.filters.tags.communityAndLifestyle,
        id: 3
    },
    conferencesAndWorkshops: {
        name: strings.filters.tags.conferencesAndWorkshops,
        id: 4
    },
    musicAndConcerts: {
        name: strings.filters.tags.musicAndConcerts,
        id: 5
    },
    educationalAndAcademic: {
        name: strings.filters.tags.educationalAndAcademic,
        id: 6
    },
    exposAndTradeShows: {
        name: strings.filters.tags.exposAndTradeShows,
        id: 7
    },
    familyAndKids: {
        name: strings.filters.tags.familyAndKids,
        id: 8
    },
    fashionAndBeauty: {
        name: strings.filters.tags.fashionAndBeauty,
        id: 9
    },
    filmAndCinema: {
        name: strings.filters.tags.filmAndCinema,
        id: 10
    },
    foodAndDrink: {
        name: strings.filters.tags.foodAndDrink,
        id: 11
    },
    healthAndWellness: {
        name: strings.filters.tags.healthAndWellness,
        id: 12
    },
    holidayAndSeasonal: {
        name: strings.filters.tags.holidayAndSeasonal,
        id: 13
    },
    outdoorAndNature: {
        name: strings.filters.tags.outdoorAndNature,
        id: 14
    },
    socialAndNetworking: {
        name: strings.filters.tags.socialAndNetworking,
        id: 15
    },
    eventsAndCelebrations: {
        name: strings.filters.tags.eventsAndCelebrations,
        id: 16
    },
    sportsAndFitness: {
        name: strings.filters.tags.sportsAndFitness,
        id: 17
    },
    technologyAndInnovation: {
        name: strings.filters.tags.technologyAndInnovation,
        id: 18
    },
    travelAndAdventure: {
        name: strings.filters.tags.travelAndAdventure,
        id: 19
    },
}
