const userProfileStrings = {
    userProfileScreen: {
        interests: 'Interests',
        moreInterests: (additionalInterestsCount: number) => `+${additionalInterestsCount} more`,
        eventsHistory: 'Events History',
        createProfileTitle: 'Create Profile',
        editProfileTitle: 'Edit Profile',
        userName: 'Name',
        birthday: 'Birthday (MM/DD/YYYY)',
        gender: 'Gender',
        sexualOrientation: 'Sexual Orientation',
        educationLevel: 'Education',
        race: 'Race',
        location: 'Location',
        description: 'Bio (min 5, max 250)',
    }
}

export default userProfileStrings;