import { useNavigate } from "react-router-dom";
import styles from "./Card.module.scss";

const Card = ({
    pageInfo
}: {
    pageInfo: any
}) => {
    const navigate = useNavigate();

    return (
        <div
            onClick={() => navigate(`/${pageInfo.url}`)}
            className={styles.card}
        >
            <div className={styles.title}>{pageInfo.title}</div>
            <div className={styles.subtitle}>{pageInfo.description}</div>
        </div>
    )
}

export default Card;