import { useState } from "react";
import styles from "./PhotoRoll.module.scss";

const PhotoRoll = ({
    photoUrls,
    isCircular = false,
}: {
    photoUrls?: string[]
    isCircular?: boolean;
}) => {
    const [selectedPhoto, setSelectedPhoto] = useState(photoUrls?.length ? photoUrls[0] : '');

    return (
        <div className={styles.photoRollContainer}>
            <img
                src={selectedPhoto}
                className={`
                    ${styles.selectedImageContainer}
                    ${isCircular ? styles.circularContainer : styles.squareContainer}
                `}
                alt="User Main Upload"
            />
            {
                photoUrls?.length && photoUrls.length > 1 && (
                    <div className={styles.subImagesContainer}>
                        {
                            photoUrls.map((photoUrl, idx) => (
                                <div
                                    key={idx}
                                    onClick={() => setSelectedPhoto(photoUrl)}
                                >
                                    <img
                                        src={photoUrl}
                                        className={`
                                            ${styles.imageContainer}
                                            ${isCircular ? styles.circularContainer : styles.squareContainer}
                                        `}
                                        alt="User Upload"
                                    />
                                </div>
                            ))
                        }
                    </div>
                )
            }
        </div>
    )
}

export default PhotoRoll;