const eventStrings = {
    eventDetailsScreen: {
        eventList: 'Event List',
        upcomingEvents: 'Upcoming Events',
        pastEvents: 'Past Events',
        additionalAttendees: (count: number) => `+${count} Others`,
        attendees: 'Attendees',
        cost: (amt: string) => `Cost: ${amt}`,
        free: 'Free',
        emptyList: 'There are no events to display.',
        emptyListCta: 'There are no events to display. Why not create one?',
        join: 'Join Event',
        leave: 'Exit Event',
        delete: 'Delete',
        edit: 'Edit',
    }
}

export default eventStrings;