const landingStrings = {
    landingScreen: {
        title: 'LampPost',
        catchphrase: 'Your Beacon for Local Events',
        download: 'Download the app!',
        ios: 'iOS',
        android: 'Android',
        login: 'Login',
        dashboard: 'Go to Dashboard'
    }
}

export default landingStrings;