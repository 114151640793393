import { useRef, useEffect } from "react";
import Label from "../label/Label";
import strings from "../../i18n/i18nStrings";
import styles from "./LocationAutocomplete.module.scss";

const LocationAutocomplete = ({
    initialLocation,
    onChange
}: {
    initialLocation?: any;
    onChange: (location: any) => void;
}) => {
    const autoCompleteRef = useRef<any>();
    const inputRef = useRef<any>();
    
    useEffect(() => {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
            inputRef.current,
            {
                fields: ['place_id', 'geometry', 'name', 'formatted_address']
            }
        );

        autoCompleteRef.current.addListener("place_changed", async () => {
            const place = await autoCompleteRef.current.getPlace();
            onChange(place);
        });

    }, [onChange]);

    return (
        <div>
            <Label label={strings.locationAutocomplete.label} />
            <input
                ref={inputRef}
                className={styles.autocompleteContainer}
                placeholder={strings.locationAutocomplete.placeholder}
                value={initialLocation?.name}
            />
        </div>
    );
};

export default LocationAutocomplete;
