import strings from "../../../i18n/i18nStrings";

export const REPEAT_VALUES = {
    none: {
        id: 0,
        name: strings.common.repeats.none,
    },
    daily: {
        id: 1,
        name: strings.common.repeats.daily,
    },
    weekly: {
        id: 2,
        name: strings.common.repeats.weekly,
    },
    monthly: {
        id: 3,
        name: strings.common.repeats.monthly,
    },
    yearly: {
        id: 4,
        name: strings.common.repeats.yearly,
    },
}