import { ReactElement } from 'react';
import styles from './PageLayout.module.css';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';

const PageLayout = ({ children }: { children: ReactElement }) => (
    <>
        <Header />
        <div className={styles.childContainer}>
            <div className={styles.childContent}>
                {children}
            </div>
        </div>
        <Footer />
    </>
)

export default PageLayout;