import { ReactComponent as LogoIcon } from '../../assets/icons/LogoIcon.svg';
import { useAppContext } from '../../contexts/AppContext';
import strings from "../../i18n/i18nStrings";
import Button, { BUTTON_VARIANTS } from '../../ui/button/Button';
import styles from "./EventList.module.scss";

const EventsList = ({
    events,
    // showAttendance,
    onEdit,
    onCreateEvent,
    onPreview,
}: {
    events: any[];
    // showAttendance?: boolean;
    onEdit?: (event: any) => void;
    onCreateEvent?: () => void;
    onPreview: (event: any) => void;
}) => {
    const { deleteEvent } = useAppContext();

    const currentDate = new Date().getTime();

    const getFormattedDate = (date: number) => {
        const eventDate = new Date(date);

        // return eventDate.toDateString();
        return strings.common.datePicker(
            new Date(eventDate).toDateString(),
            new Date(eventDate).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })
        );
    }

    return events?.length ? (
        <>
            {
                events.map((eventData) => (
                    <div className={styles.eventContainer} key={eventData._id}>
                        <div className={styles.eventInfoContainer}>
                            <div className={styles.eventDataContainer}>
                                {
                                    eventData?.images[0] ? (
                                        <img
                                            src={eventData?.images[0]}
                                            className={styles.image}
                                            alt="Event Descriptor"
                                        />
                                    ) : (
                                        <div className={styles.image}>
                                            <LogoIcon />
                                        </div>
                                    )
                                }
                                <div className={styles.descriptionContainer}>
                                    <div className={styles.descriptionRow}>
                                        <span>{eventData.name}</span>
                                    </div>
                                    <div className={styles.descriptionRow}>
                                        <span>{eventData.location.name}</span>
                                    </div>
                                    <div className={styles.descriptionRow}>
                                        <span>{getFormattedDate(eventData.date)}</span>
                                    </div>
                                </div>
                            </div>
                            <p>{eventData.description}</p>
                        </div>
                            <div className={styles.buttonContainer}>
                                <Button onPress={() => onPreview(eventData)}>
                                    <span>{strings.common.preview}</span>
                                </Button>
                                    
                            {
                                currentDate < eventData.date && onEdit && (
                                    <>
                                        <Button
                                            onPress={() => onEdit(eventData)}
                                            roleVariant={BUTTON_VARIANTS.SECONDARY}
                                        >
                                            <span>{strings.common.edit}</span>
                                        </Button>
                                        <Button
                                            onPress={() => deleteEvent(eventData._id)}
                                            roleVariant={BUTTON_VARIANTS.DESTRUCTIVE}
                                        >
                                            <span>{strings.common.delete}</span>
                                        </Button>
                                    </>
                                )
                            }
                        </div>
                    </div>
                ))
            }
        </>
    ) : (
        <div>
            {
                onEdit && onCreateEvent ? (
                    <div>
                        <p>{strings.eventDetailsScreen.emptyListCta}</p>
                        <Button
                            onPress={onCreateEvent}
                            roleVariant={BUTTON_VARIANTS.CTA}
                        >
                            <span>{strings.createOrEditEventScreen.createEvent}</span>
                        </Button>
                    </div>
                ) : (
                    <div>
                        <p>{strings.eventDetailsScreen.emptyList}</p>
                    </div>
                )
            }
        </div>
    )
}

export default EventsList;